import React from "react";
import "../App.css";
import { privacyPolicy } from "../assets/legals/legals";
import TextComponent from "./textComponent";
import Distancer from "./distancer";

const PrivacyPolicy = () => {
  return (
    <>
      <Distancer distance={"5vh"}></Distancer>
      <TextComponent content={privacyPolicy}></TextComponent>
      <Distancer distance={"10vh"}></Distancer>
    </>
  );
};

export default PrivacyPolicy;
