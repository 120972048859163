export const address = (
  <>
    <>Siempi AG</>
    <br />
    <>Birkenstrasse 47</>
    <br />
    <>6343 Rotkreuz</>
    <br />
    <>Switzerland</>
    <br />
    <>info@siempi.ch</>
    <br />
    <br />
    <>Legal seat: Risch</>
    <br />
    <>Registration number: CHE-369.093.556</>
  </>
);

export const termsOfService = {
  title: <h1>Terms of Use</h1>,
  lastUpdated: "Last Updated: Mai 2, 2024",
  paragraphs: [
    {
      title: "1. General Information",
      text: 'These Terms of Service ("Terms") govern your access and use of https://siempi.ch/ ("the Website"), operated by Siempi AG ("we" or "us"). The Website serves as a technical interface that operates on the Ethereum blockchain. Our platform facilitates the creation of ERC20 tokens through smart contracts, which are used to establish legally binding agreements directly on the blockchain. Users must connect their MetaMask wallets to interact with the services offered. Each time you access the Website, you irrevocably agree to be bound by the Terms posted on the Website.',
    },
    {
      title: "2. User Acknowledgements",
      text: "You acknowledge that all transactions initiated via our Website, are performed on blockchain networks using MetaMask and are governed by their respective privacy policies and terms. Siempi AG is not affiliated with the Ethereum organization and does not control, influence, or guarantee the functionality of the Ethereum blockchain or any other blockchain network. You understand the inherent risks associated with blockchain technology and agree to them.",
    },
    {
      title: "3. Eligibility",
      text: "You must be at least the age of majority in your jurisdiction to use our Website. You affirm that your use of the Website will comply with all applicable laws and regulations. Additionally, you confirm that you are not located in or a resident of any jurisdiction under trade embargoes or sanctions by your government.",
    },
    {
      title: "4. Disclaimer",
      text: 'The Website is provided on an "as is" and "as available" basis without warranties of any kind. We do not offer legal, financial, or investment advice, and no information provided should be taken as such. We disclaim all liability related to your use of the Website. Additionally, Siempi AG makes no guarantees that the Website is free of errors, mistakes, misrepresentations, or failures. References and links to third-party websites are outside our area of responsibility, and we reject any responsibility for such websites. Users will act at their own risk in accessing or relying on the content of the Website and are solely responsible for any consequences thereof. ',
    },
    {
      title: "5. Intellectual Property",
      text: "All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of Siempi AG or its content suppliers and is protected by international copyright laws. You may not reproduce, distribute, modify, transmit, reuse, or adapt any portion of this website without express written consent from us.",
    },
    {
      title: "6. Violations and Disclosures",
      text: "We reserve the right to disclose any information necessary to satisfy any applicable law, regulation, legal process, or governmental request. If you violate these Terms, we may take legal action against you and you agree to be responsible for legal fees in such cases.",
    },
    {
      title: "7. Changes to Terms",
      text: "Siempi AG reserves the right to modify these Terms at any time. We will provide notice of these changes by revising the date at the top of the Terms. Your continued use of the Website after such changes will constitute acceptance of the new Terms.",
    },
    {
      title: "8. Contact Us",
      text: "If you have any questions or concerns regarding these Terms, please contact us at",
    },
  ],
  address: address,
};

export const privacyPolicy = {
  title: <h1>Privacy Policy</h1>,
  lastUpdated: "Last Updated: Mai 9, 2024",
  paragraphs: [
    {
      title: "1. Introduction",
      text: 'Welcome to https://siempi.ch/ ("Website"). Siempi AG ("we") prioritizes your privacy. This Privacy Policy outlines our practices regarding the collection of personal information through this website.',
    },
    {
      title: "2. Services in use",
      text: " • Google Analytics",
    },
    {
      title: "3. Data Controller and Responsibilities",
      text: (
        <>
          <>
            Siempi AG is responsible for data protection on the Website, unless expressly stated otherwise. You can
            contact us regarding data protection matters at
          </>
          <br />
          <br />
          <>{address}</>
          <br />
          <br />
          <>
            For conducting cryptocurrency transactions, users must utilize third-party wallet extensions such as
            MetaMask, which operate independently, and we do not control their operations or assume any legal
            responsibility for privacy matters related to their use. All transactions, including the creation and
            management of ERC-20 tokens, are performed directly on the Ethereum blockchain through these extensions.
          </>
        </>
      ),
    },
    {
      title: "4. Data Security",
      text: "We take appropriate technological and organizational security precautions to protect your personal data against unauthorized access and misuse, such as issuing instructions, training, IT and network security solutions, access controls and restrictions, encrypting data carriers and transmissions, pseudonymization and checks.     ",
    },
    {
      title: "5. Support Communications",
      text: "We may gather information you provide directly to us during communications with our support staff. This is limited to the context of your inquiries or support needs and is used solely to enhance the quality of our customer service and your interactions with our support team.",
    },
    {
      title: "6. Disclosure of Personal Information",
      text: "We adhere to strict privacy standards and will not disclose any collected information to third parties unless required by law or regulation.",
    },
    {
      title: "8. Cookies",
      text: 'When you visit our Website, we may utilize commonly employed methods such as cookies, flash cookies, pixel tags, and other tracking technologies ("Cookies") to improve your browsing experience and gather information about how you interact with our Website.',
    },
    {
      title: "9. Individual Data Processing Operations",
      subsections: [
        {
          title: "9.1 Providing the website and creating log files",
          text: "What information do we receive, and how do we use it? By visiting this website, certain data is automatically stored on our servers or on servers of services and products that we obtain and/or have installed, for purposes of system administration, for statistical or backup purposes or for tracking purposes. This includes the name of your Internet service provider, your IP address (in some circumstances), the version of your browser software, the operating system of the computer with which the URL is accessed, the date and time of access, the web page from which you are visiting URL, and the search words you used to find the URL.",
        },
        {
          title: "9.2 Google Analytics",
          text: 'We use Google Analytics on our website, a web analytics service provided by Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; "Google"). Google Analytics uses “cookies”, which are text files placed on your computer, to help the website analyze how users use the site. You may refuse the use of cookies by selecting the appropriate settings on your browser. You can also prevent the collection of data generated by the cookie and related to your use of the website (including your IP address) to Google and the processing of this data by Google by downloading and installing the browser plugin available at the following link: https://tools.google.com/dlpage/gaoptout.',
        },
      ],
    },
    {
      title: "10. User Conduct",
      text: "Your use of this website must comply with all applicable laws and regulations, including but not limited to gaming, gambling, financial markets, and export control laws within your jurisdiction. You are responsible for ensuring that your activities on the site adhere to these legal standards.",
    },
  ],
  address: (
    <>
      <br />
      <br />
    </>
  ),
};
