import React from "react";
import "../App.css";
import TextComponent from "./textComponent";
import { termsOfService } from "../assets/legals/legals";
import Distancer from "./distancer";

const TermsOfUse = () => {
  return (
    <>
      <Distancer distance={"5vh"}></Distancer>
      <TextComponent content={termsOfService}></TextComponent>
      <Distancer distance={"10vh"}></Distancer>
    </>
  );
};

export default TermsOfUse;
