import { useLocation, useNavigate } from "react-router-dom";

const TextComponent = ({ content }) => {
  const navigate = useNavigate();
  const location = useLocation(); // Access location object

  let metadata = {
    title: "Privacy Policy | Siempi.ch", // Default title
    description:
      "Siempi AG prioritizes your privacy. This Privacy Policy outlines our practices regarding the collection of personal information through this website.",
    keywords:
      "erc20 token development, create erc20 token, erc20 token creation, make erc20 token, l2 solutions, how to make a meme coin, how to create a meme coin",
  };

  if (location.pathname.endsWith("/termsOfUse")) {
    metadata.title = "Terms of Service | Siempi.ch";
    metadata.description =
      "By using our Website, you confirm that you are at least the age of majority in your jurisdiction and that your usage will comply with all applicable laws and regulations.";
    metadata.keywords =
      "erc20 token development, create erc20 token, erc20 token creation, make erc20 token, l2 solutions, how to make a meme coin, how to create a meme coin";
  }
  
  const renderParagraphs = (paragraphs, level = 0) => {
    return paragraphs.map((item, index) => (
      <div key={`paragraph-${index}`}>
        <div
          style={{
            margin: `20px 20px 20px ${20 + level * 20}px`,
            fontWeight: "600",
          }}
        >
          {item.title}
        </div>
        <div style={{ margin: `20px 20px 20px ${20 + level * 20}px` }}>
          {item.text}
        </div>
        {item.subsections && renderParagraphs(item.subsections, level + 1)}
      </div>
    ));
  };

  return (
    <>
      <div
        style={{
          color: "gainsboro",
          width: "95%",
          maxWidth: "800px",
          fontSize: "14px",
          lineHeight: 1.3,
          textAlign: "left",
          margin: "80px auto 20px auto",
        }}
      >
        <div
          style={{
            margin: "5px",
            fontSize: "20px",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          {content.title}
        </div>
        <div style={{ margin: "20px", textAlign: "center" }}>
          {content.lastUpdated}
        </div>
        {renderParagraphs(content.paragraphs)}
        <div style={{ margin: "20px" }}>{content.address}</div>
      </div>
    </>
  );
};

export default TextComponent;
