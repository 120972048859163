import React from "react";
import "../styles/footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <nav className="footer-nav">
        <a href="termsOfUse" className="footer-link">
          Terms Of Use
        </a>
        <a href="privacyPolicy" className="footer-link">
          Privacy Policy
        </a>
      </nav>
      <span className="footer-copyright">Copyright © by Siempi AG 2024.</span>
    </footer>
  );
};

export default Footer;
