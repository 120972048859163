import "./App.css";
import TermsOfUse from "./components/termsOfUse";
import PrivacyPolicy from "./components/privacyPolicy";
import Home from "./components/home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";

function App() {

  return (
    <Router>
      <Header />
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/termsOfUse" element={<TermsOfUse />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
