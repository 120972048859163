import React, { useState } from "react";
import "../App.css"; // Ensure your CSS file is imported

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStatusMessage("");

    try {
      const response = await fetch("https://bwnjqx6j5m.execute-api.eu-central-1.amazonaws.com/prod/sendSubmission", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatusMessage("Message sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      } else {
        setStatusMessage("Failed to send message.");
      }
    } catch (error) {
      console.error("Error:", error);
      setStatusMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="contactFormContainer">
      <h2 className="basicTitle">Contact Us</h2>
      <form id="contactForm" onSubmit={handleSubmit}>
        <label htmlFor="name" className="formLabel">
          Name:
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="formInput"
        />

        <label htmlFor="email" className="formLabel">
          Email:
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className="formInput"
        />

        <label htmlFor="message" className="formLabel">
          Message:
        </label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          className="formTextarea"
        />

        <button type="submit" className="formButton color2">
          Submit
        </button>
      </form>

      {statusMessage && <p className="statusMessage">{statusMessage}</p>}
    </div>
  );
};

export default ContactForm;
